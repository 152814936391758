import React from 'react';
import Css from "./ContactImgSection.module.css"

const ContactImgSection = props => {
    
    let data = props.content["image-section"]
    let bgColor = (props.bgColor) ? props.bgColor : "#ffffff" 

    return (

        <div className={Css.ImgSection} style={{ backgroundColor: bgColor }} >
        <img className={Css.Picture} src={require('../../images/' + data['image'])} alt="Hello!" />
        </div>
    )
}

export default ContactImgSection