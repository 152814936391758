import React from "react"
import Css from "./ContactFormSection.module.css"

const ContactFormSection = props => {
  let data = props.content["form"]
  let bgColor = props.bgColor ? props.bgColor : "#F5F6F7"

  let classNames = "action-button " + Css.Send

  return (
    <div className={Css.FormSection} style={{ backgroundColor: bgColor }}>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <input
          className={Css.Text}
          type="text"
          name="name"
          id="name"
          placeholder={data["name"]}
        />
        <input
          className={Css.Email}
          type="email"
          name="from"
          id="from"
          placeholder={data["email"]}
        />
        <input
          className={Css.Text}
          type="text"
          name="company"
          id="company"
          placeholder={data["company"]}
        />
        <textarea
          className={Css.Textarea}
          name="body"
          id="body"
          placeholder={data["textarea"]}
        ></textarea>
        <div data-netlify-recaptcha="true"></div>
        <button type="submit" className={classNames}>
          Send Message
        </button>
      </form>
    </div>
  )
}

export default ContactFormSection
