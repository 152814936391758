import React from "react"
import Css from "./LetsConnectSection.module.css"
import LetsConnnectImg from "../images/lets_connect.png"

const LetsConnectSection = props => {
  let items = []
  let data = props.content["lets-connect-section"]
  if (data && data["contacts"]) {
    items = data["contacts"].map((contact, idx) => {
      return  <div key={idx} className={Css.Info}>
          <div className={Css.InfoTitle}>{contact.title}</div>
          <div className={Css.InfoDesc}>{contact.description}</div>
          <div className={Css.InfoEmail}><a href={`mailto:${contact.email}`}>{contact.email}</a></div>
          <div className={Css.InfoPhone}><a href={`tel:${contact.email}`}>{contact.phone}</a></div>
          <div className={Css.InfoSeparatorBlock}>
            <div className={Css.InfoSeparator}>&nbsp;</div>
          </div>
        </div>
    })
  }

  return (
    <div className={Css.LetsConnectSection}  >
      { data["title1"] &&
        <>
        <div className={Css.TitleBig} >{data["title1"]}</div>
        <img className={Css.LetsConnectImg} src={LetsConnnectImg} alt="Connect people" />
        </>
      }
      {data["contacts"] &&
        <div className={Css.ContactInfoContainer} >{items}</div>
      }
    </div>
  )
}

export default LetsConnectSection