import React from "react"

import Seo from "../components/Seo"
import GenericHero from "../components/GenericHero"
import SiteHeader from "../components/SiteHeader"
import TopImageContainer from "../components/TopImageContainer"
import LetsConnectSection from "../components/LetsConnectSection"
import SiteFooter from "../components/SiteFooter"

import ContactFormSection from "../components/Contact/ContactFormSection"
import ContactImgSection from "../components/Contact/ContactImgSection"

import TopImage from "../images/top_contact_bg.png"

import content from "../data/Content"

export default function Contact() {
  let page = "contact"
  let data = content(page)
  return (
    <div className="content">
      <Seo page={content(page)} />
      <TopImageContainer image={TopImage}>
        <SiteHeader page={page} content={content("header")} />
        <GenericHero content={data} />
      </TopImageContainer>
      <ContactFormSection content={content("contact")} />
      <LetsConnectSection content={content("contact")} />
      <ContactImgSection content={content("contact")} />
      <SiteFooter content={content("footer")} />
    </div>
  )
}
